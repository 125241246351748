import React from "react";

// reactstrap components

// core components

function Header() {
  return (
    <>
      <div className="" id="header">
        <div
          className="page-header"
          style={{
            backgroundImage:
              "url(" + require("assets/img/header.jpg").default + ")",
          }}
        >
          <div className="filter" />
          <div className="content-center">
            <div className="motto">
              <h1 className="bahnschrift text-center">Affordable AI for everyone</h1>
              <h3 className="text-center">Aiseeyou focuses on Industry 4.0 needs, mainly on AI-powered service, IoT and data analytics.</h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
