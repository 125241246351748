import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function ContactUs() {
    return (
        <>
            <div className="section">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="8">
                            <h2 className="bahnschrift title">Contact Us</h2>
                            <p>
                                Jalan Anggrek 5 Blok A55 no 4, Pamulang, Tangerang Selatan<br />
                                admin@aiseeyou.tech<br />
                                Widjaja +62 821 1399 3202
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default ContactUs;
