import React from 'react'

// reactstrap components
import { Container } from "reactstrap";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3
  }
};

export default function Partners() {
  return (
    <>
      <div className="section" id="partners">
        <Container>
          <h2 className="bahnschrift title text-center">Partners</h2>
          <Carousel responsive={responsive} showDots={true}>
            <div className='h-100'>
              <div className='d-flex align-items-center justify-content-center' style={{ height: '80%', marginBottom: '5%' }}>
                <img
                  alt="..."
                  className="mh-100 mw-75 w-75 img-no-padding"
                  src={require("assets/img/logo-uq.png").default}
                />
              </div>
              <h5 className="text-center">Ummul Quro</h5>
            </div>
            <div className='h-100'>
              <div className='d-flex align-items-center justify-content-center' style={{ height: '80%', marginBottom: '5%' }}>
                <img
                  alt="..."
                  className="mh-100 mw-75 w-75 img-no-padding"
                  src={require("assets/img/logo-rssa.png").default}
                />
              </div>
              <h5 className="text-center">RSSA</h5>
            </div>
            <div className='h-100'>
              <div className='d-flex align-items-center justify-content-center' style={{ height: '80%', marginBottom: '5%' }}>
                <img
                  alt="..."
                  className="mh-100 mw-75 w-75 img-no-padding"
                  src={require("assets/img/logo-bpjs.png").default}
                />
              </div>
              <h5 className="text-center">BPJS TK</h5>
            </div>
            <div className='h-100'>
              <div className='d-flex align-items-center justify-content-center' style={{ height: '80%', marginBottom: '5%' }}>
                <img
                  alt="..."
                  className="mh-100 mw-75 w-75 img-no-padding"
                  src={require("assets/img/logo-bpjsks.png").default}
                />
              </div>
              <h5 className="text-center">BPJS KESEHATAN</h5>
            </div>
            <div className='h-100'>
              <div className='d-flex align-items-center justify-content-center' style={{ height: '80%', marginBottom: '5%' }}>
                <img
                  alt="..."
                  className="mh-100 mw-75 w-75 img-no-padding"
                  src={require("assets/img/logo-yamaha.png").default}
                />
              </div>
              <h5 className="text-center">YAMAHA</h5>
            </div>
            <div className='h-100'>
              <div className='d-flex align-items-center justify-content-center' style={{ height: '80%', marginBottom: '5%' }}>
                <img
                  alt="..."
                  className="mh-100 mw-75 w-75 img-no-padding"
                  src={require("assets/img/logo-adivisia.jpg").default}
                />
              </div>
              <h5 className="text-center">ADIVISIA</h5>
            </div>
            <div className='h-100'>
              <div className='d-flex align-items-center justify-content-center' style={{ height: '80%', marginBottom: '5%' }}>
                <img
                  alt="..."
                  className="mh-100 mw-75 w-75 img-no-padding"
                  src={require("assets/img/logo-almar.png").default}
                />
              </div>
              <h5 className="text-center">ALMAR</h5>
            </div>
            <div className='h-100'>
              <div className='d-flex align-items-center justify-content-center' style={{ height: '80%', marginBottom: '5%' }}>
                <img
                  alt="..."
                  className="mh-100 mw-75 w-75 img-no-padding"
                  src={require("assets/img/logo-bahasakita.webp").default}
                />
              </div>
              <p className="text-center">BAHASA KITA</p>
            </div>
            <div className='h-100'>
              <div className='d-flex align-items-center justify-content-center' style={{ height: '80%', marginBottom: '5%' }}>
                <img
                  alt="..."
                  className="mh-100 mw-75 w-75 img-no-padding"
                  src={require("assets/img/logo-litbang.png").default}
                />
              </div>
              <p className="text-center">PUSLITBANG POLRI</p>
            </div>
          </Carousel>
        </Container>
      </div>
    </>
  )
}
