import React from 'react'

// reactstrap components
import { Button } from "reactstrap";

const floatStyle = {
    position: 'fixed',
    bottom: '40px',
    right: '40px',
    zIndex: '100',
}

export default function FloatingButton() {
    return (
        <a href="https://api.whatsapp.com/send/?phone=6283893664644&text=Hallo%20Pak%20Ashar%2C%20kami%20ingin%20mengenal%20Aiseeyou&app_absent=0" style={floatStyle} target="_blank" rel="noreferrer">
            <Button
                className="btn-just-icon btn-success"
                color="default"
                size="lg"
                type="button"
                style={floatStyle}
            >
                <i className="fa fa-whatsapp" />
            </Button>
        </a>
    )
}
