import React from 'react'

// reactstrap components
import { Container, Row, Col } from "reactstrap";


export default function Services() {
  return (
    <div className="section" id="services">
      <Container>
        <h2 className="bahnschrift title text-center">Services</h2>
        <Row>
          <Col className="mr-auto ml-auto" md='3'>
            <div className='info'>
              <div className='icon icon-danger'>
                <i className='nc-icon nc-chat-33' />
              </div>
              <h4 className="title">CONSULTANCY</h4>
            </div>
          </Col>
          <Col className="mr-auto ml-auto" md='3'>
            <div className='info'>
              <div className='icon icon-danger'>
                <i className='nc-icon nc-settings' />
              </div>
              <h4 className="title">CUSTOM DEVELOPMENT</h4>
            </div>
          </Col>
          <Col className="mr-auto ml-auto" md='3'>
            <div className='info'>
              <div className='icon icon-danger'>
                <i className='nc-icon nc-satisfied' />
              </div>
              <h4 className="title">PARTNERSHIP</h4>
            </div>
          </Col>
          <Col className="mr-auto ml-auto" md='3'>
            <div className='info'>
              <div className='icon icon-danger'>
                <i className='nc-icon nc-refresh-69' />
              </div>
              <h4 className="title">MAINTENANCE</h4>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
