import React from 'react'

// reactstrap components
import { Container, Row, Col } from "reactstrap";

export default function Products() {
  return (
    <div className="section section-gray" id="products">
      <Container>
        <h2 className="bahnschrift title text-center">Products</h2>
        <h3 className="text-info text-center font-weight-bold mb-4">ARTIFICIAL INTELLIGENCE</h3>
        <Row className='mb-4'>
          <Col md='5'>
            <div className="card-image">
              <img
                alt="..."
                className="w-100 rounded"
                src={require("assets/img/Biometrics.png").default}
              />
            </div>
          </Col>
          <Col className='py-5' md='7'>
            <h3 className='w-100 text-center text-md-left'>Face & Voice Recognition</h3>
            <p className='text-center text-md-left'>Enrollment, Identification, Verification, Surveillance, e-KYC</p>
          </Col>
        </Row>
        <Row className='mb-4'>
          <Col className='order-md-last' md='5'>
            <div className="card-image">
              <img
                alt="..."
                className="w-100 rounded"
                src={require("assets/img/Rontia.png").default}
              />
            </div>
          </Col>
          <Col className='py-5' md='7'>
            <h3 className='w-100 text-center text-md-right'>DISEASE RECOGNITION</h3>
            <p className='text-center text-md-right'>Thorax roentgen image to recognize most lesions in patients</p>
          </Col>
        </Row>
        <Row className='mb-4'>
          <Col md='5'>
            <div className="card-image">
              <img
                alt="..."
                className="w-100 rounded"
                src={require("assets/img/Traffic-Analysis.png").default}
              />
            </div>
          </Col>
          <Col className='py-5' md='7'>
            <h3 className='w-100 text-center text-md-left'>TRAFFIC ANALYTIC</h3>
            <p className='text-center text-md-left'>Vehicle counting, Vehicle tracking, Analytics Dashboard</p>
          </Col>
        </Row>
        <Row className='mb-4'>
          <Col className='order-md-last' md='5'>
            <div className="card-image">
              <img
                alt="..."
                className="w-100 rounded"
                src={require("assets/img/Sosmetic.png").default}
              />
            </div>
          </Col>
          <Col className='py-5' md='7'>
            <h3 className='w-100 text-center text-md-right'>SOCIAL MEDIA & NEWS ANALYTICS</h3>
            <p className='text-center text-md-right'>Suitable for electability analysis, marketing research. Reduce noise, get the insights and make good decision with the help of sentiment analysis, emotion analysis, named entity recognition, statement recognition. Bahasa Indonesia is supported.</p>
          </Col>
        </Row>
        <Row className='mb-4'>
          <Col md='5'>
            <div className="card-image">
              <img
                alt="..."
                className="w-100 rounded"
                src={require("assets/img/Chatbot.png").default}
              />
            </div>
          </Col>
          <Col className='py-5' md='7'>
            <h3 className='w-100 text-center text-md-left'>CHATBOT</h3>
            <p className='text-center text-md-left'>Chatbot can help reduce time to answer frequently asked questions, such as how many leaves a employee has in a company.</p>
          </Col>
        </Row>

        <h3 className="text-info text-center font-weight-bold pt-4 mb-4">INTERNET OF THINGS</h3>
        <Row className='mb-4 justify-content-center'>
          <Col md='7'>
            <div className="card-image">
              <img
                alt="..."
                className="w-100 rounded"
                src={require("assets/img/IoT-1.png").default}
              />
            </div>
          </Col>
        </Row>

        <h3 className="text-info text-center font-weight-bold pt-4 mb-4">BIG DATA</h3>
        <Row className='mb-4 justify-content-center'>
          <Col md='7'>
            <div className="card-image">
              <img
                alt="..."
                className="w-100 rounded"
                src={require("assets/img/IoT-2.png").default}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
