import React from "react";

import Header from "./landing-page/Header.js";
import ContactUs from "./landing-page/ContactUs.js";
import Partners from "./landing-page/Partners.js";
import Products from "./landing-page/Products.js";
import Services from "./landing-page/Services.js";
import CompanyProfile from "./landing-page/CompanyProfile.js";
import NavbarTransparent from "./landing-page/NavbarTransparent.js";
import FloatingButton from "./landing-page/FloatingButton.js";

const arraySection = [
  'header',
  'partners',
  'products',
  'services',
  'companyprofile'
]

function Index() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#/") + 2
    );
    var hrefId = href.substring(href.lastIndexOf("#") + 1);
    if (href.lastIndexOf("#") > 0) {
      if (arraySection.includes(hrefId)) {
        document.getElementById(hrefId).scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }

    return function cleanup() {
      document.body.classList.remove("index-page");
    };
  });
  return (
    <>
      <NavbarTransparent />
      <div className="section-space" />
      <Header />
      <Partners />
      <Products />
      <Services />
      <CompanyProfile />
      <ContactUs />
      <FloatingButton />
    </>
  );
}

export default Index;
