import React from 'react'

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";

// import CompanyProfileFile from 'assets/file/Company Profile 20220516.pptx'

const styleCol = { display: 'flex', alignItems: 'center', justifyContent: 'center' }

export default function CompanyProfile() {
  return (
    <div className="section section-gray" id="companyprofile">
      <Container>
        <h2 className="bahnschrift title text-center">Company Profile</h2>
        <Row className='ml-auto mr-auto' style={{ maxWidth: '500px' }}>
          <Col md='6' sm='12'>
            <img
              className='w-100'
              alt="..."
              src={require("assets/img/microsoft-power-point.svg").default}
            />
          </Col>
          <Col md='6' sm='12' style={styleCol}>
            <div>
              {/* <a href={CompanyProfileFile} download="Company Profile" target='_blank' rel="noreferrer"> */}
              <a href='#' download="Company Profile" target='_blank' rel="noreferrer">
                <Button className="btn-fill" color="danger" size="lg">
                  Click To Download
                </Button>
              </a>
              <h6 className='text-center'>last update 05/16/2022</h6>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
