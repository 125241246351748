/*!

=========================================================
* Paper Kit PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-pro-react
* Copyright 2021 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import { hydrate, render } from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
// pages
import Index from "views/Index.js";

// ReactDOM.render(
//   <BrowserRouter>
//     <Switch>
//       <Route path="/index" render={(props) => <Index {...props} />} />
//       <Redirect to="/index" />
//     </Switch>
//   </BrowserRouter>,
//   document.getElementById("root")
// );


const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
  <BrowserRouter>
    <Switch>
      <Route path="/index" render={(props) => <Index {...props} />} />
      <Redirect to="/index" />
    </Switch>
  </BrowserRouter>, rootElement);
} else {
  render(
  <BrowserRouter>
    <Switch>
      <Route path="/index" render={(props) => <Index {...props} />} />
      <Redirect to="/index" />
    </Switch>
  </BrowserRouter>, rootElement);
}